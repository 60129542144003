<script setup lang="ts" generic="T extends InputTextfieldProps">
// icons
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';

/********************
 * PROPS & EMITS     *
 ********************/
export interface InputTextfieldProps {
  cols?: string;
  disabled?: boolean;
  error?: boolean;
  inverted?: boolean;
  label?: string;
  modelValue: T;
  passwordToggle?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  rows?: string;
  type: string;
}
const props = withDefaults(defineProps<InputTextfieldProps>(), {
  cols: '4',
  disabled: false,
  error: false,
  inverted: false,
  label: '',
  passwordToggle: false,
  placeholder: '',
  readOnly: false,
  required: false,
  rows: '4',
});

const emit = defineEmits<{
  input: [value: string | number];
  blur: [value: Event];
}>();

defineOptions({
  inheritAttrs: false,
});

/********************
 * COMPOSITIONS      *
 ********************/
const $attrs = useAttrs();
const { $gettext } = useGettext();

/********************
 * REFS & VARS       *
 ********************/
const model = defineModel<T>();
const inputType = ref(props.type);
const passwordVisible = ref(false);

const eyeCon = computed(() => {
  return passwordVisible.value ? faEyeSlash : faEye;
});

const isInputField = computed(() => {
  return props.type === 'text' || props.type === 'email' || props.type === 'password' || props.type === 'number';
});

const isTextarea = computed(() => {
  return props.type === 'textarea';
});

const charactersLeft = computed(() => {
  if (!$attrs.maxlength) {
    return false;
  }

  return $attrs.maxlength - (model.value?.length || 0);
});

const charactersNeeded = computed(() => {
  const minLength = $attrs.minlength;
  if (!minLength) {
    return false;
  }

  const needed = minLength - (model.value?.length || 0);
  return needed > 0 ? needed : '0';
});

/********************
 * FUNCTIONS         *
 ********************/
function toggleVisibility() {
  passwordVisible.value = !passwordVisible.value;
  inputType.value = passwordVisible.value ? 'text' : 'password';
}
</script>

<template>
  <div
    :class="[`input-wrapper--${$attrs.id}`, error ? 'has-error' : '', $attrs.class]"
    class="mb-6 flex flex-col"
  >
    <div class="input-label__wrapper relative">
      <label
        :for="$attrs.id"
        class="input-label text-left"
      >
        <span>{{ props.label }}<sup v-if="props.required">*</sup></span>
      </label>
      <slot />
    </div>

    <div class="input-field__wrapper relative flex grow">
      <input
        v-if="isInputField"
        :id="$attrs.id"
        v-model="model"
        :class="{ 'input--inverted': props.inverted }"
        :type="inputType"
        :name="$attrs.name"
        :disabled="props.disabled"
        :placeholder="props.placeholder"
        :readonly="props.readOnly"
        v-bind="$attrs"
        class="input-field input-field--text"
        @blur="emit('blur', $event)"
      >

      <CiButton
        v-if="props.passwordToggle && props.type === 'password'"
        class="button button--light button--radius-small input-field__show-password absolute"
        type="button"
        :icon="eyeCon"
        icon-ratio="0.75"
        @click="toggleVisibility"
      />

      <textarea
        v-if="isTextarea"
        :id="$attrs.id"
        v-model="model"
        :rows="props.rows"
        :cols="props.cols"
        :class="{ 'input--inverted': props.inverted }"
        :placeholder="props.placeholder"
        :name="$attrs.name"
        :disabled="props.disabled"
        v-bind="$attrs"
        class="input-field input-field--textarea"
      />
    </div>

    <div
      v-if="charactersLeft"
      class="input__remaining"
      :class="{ 'input__remaining--warning': charactersLeft < 10 }"
    >
      {{ charactersLeft }} <span>{{ $gettext('characters left') }}</span>
    </div>

    <div
      v-if="charactersNeeded"
      class="input__remaining"
    >
      <span v-if="charactersNeeded !== '0'">{{ charactersNeeded }} <span>{{ $gettext('more characters needed') }}</span></span>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.input-field--text,
.input-field--textarea {
  @apply px-6 py-4;
}

.input-field__show-password {
  @apply right-2 top-1/2 z-20 -translate-y-1/2 transform;
}

.input__remaining {
  @apply mt-2 text-right;
}

.input__remaining--warning {
  @apply text-warning;
}
</style>
